import Busan from "assets/img/empLogo/Busan.svg";
import TechPrice from "assets/img/empLogo/techprice.svg";

export const ShareEmpList = [
  require("assets/img/empLogo/bepa.jpg"),
  require("assets/img/empLogo/kmuholding.png"),
  require("assets/img/empLogo/kookmin_university.jpg"),
  require("assets/img/empLogo/technopark.png"),
  require("assets/img/empLogo/ic_busan_logo.jpg"),
  Busan,
  TechPrice,
];
